import { Link } from "react-router-dom";
import { getInteractions } from "../data";

export default function Interactions() {
    let interactions = getInteractions();
    
    return (
        <div>
            <h2>Your interactions</h2>
            <div style={{ display: "flex" }}>
                <nav
                    style={{
                    borderRight: "solid 1px",
                    padding: "1rem"
                    }}
                >
                    {interactions.map(interactions => (
                    <Link
                        style={{ display: "block", margin: "1rem 0" }}
                        to={`/interactions/${interactions.number}`}
                        key={interactions.number}
                    >
                        {interactions.name}
                    </Link>
                    ))}
                </nav>
            </div>
        </div>
    );
  }