import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import Space from "./routes/space";
import Interactions from "./routes/interactions";

import reportWebVitals from './reportWebVitals';
import { render } from '@testing-library/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App/>}>
          <Route path="space" element={<Space />} />
          <Route path="interactions" element={<Interactions/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
