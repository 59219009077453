import logo from './images/arden_big.png';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import Preferences from './components/Preferences/Preferences';
import { Outlet, Link } from "react-router-dom";

function App() {
  return (
    <div className="wrapper">
      <img src={logo} className="logo"/>
      <nav
        style={{
          borderBottom: "solid 1px",
          paddingBottom: "1rem"
        }}
      >
        <Link to="/space">Your space</Link> |{" "}
        <Link to="/interactions">Your interactions</Link>
      </nav>
      <Outlet/>
    </div>
  );
}

export default App;
